<template>
  <div class="card">
    <action-overlay-view right="0px" top="0px">
      <template>
        <div class="card-content is-relative">
          <!-- Profile info -->
          <div class="is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
            <profile-picture class="is-120x120 mb-2" :picture="picture" :initials="initials"></profile-picture>
            <div class="title has-text-centered">
              {{ fullName }}
            </div>
            <div class="subtitle has-text-centered">
              {{ positionAndCompany }}
            </div>
            <div v-if="isReceived" @click="acceptInvitation(userUuid)" class="button is-rounded is-primary accept-button">
              {{ $t("friends.invitation_accept") }}
            </div>
            <div v-if="isReceived" @click="rejectInvitation(userUuid)" class="button is-rounded is-light has-text-white reject-button">
              {{ $t("friends.invitation_reject") }}
            </div>
            <div v-else @click="rejectInvitation(userUuid)" class="button is-rounded is-light has-text-white cancel-button">
              {{ $t("friends.invitation_cancel") }}
            </div>
          </div>
        </div>
      </template>
      <template #overlay>
        <div class="info-button mt-1 mr-1">
          <info-icon @click="openUserInfo()" class="has-fill-primary"></info-icon>
        </div>
      </template>
    </action-overlay-view>
  </div>
</template>

<script>
import Constants from "@/web/constants";
import { initials, fullName, positionAndCompany } from "@/shared/utils";
import ProfilePicture from "@/shared/components/common/profile/ProfilePicture";
import UserProfileModal from "@/web/components/profile/UserProfileModal";
import InfoIcon from "@/assets/icons/info.svg";
import ActionOverlayView from "@/web/components/shared/ActionOverlayView";
import { mapActions } from "vuex";

export default {
  name: "FriendInvitation",

  components: { ProfilePicture, InfoIcon, ActionOverlayView },

  props: {
    invitation: {
      type: Object,
    },
  },

  computed: {
    userUuid() {
      return this.invitation.user.user_identity_token;
    },

    fullName() {
      return fullName(this.invitation.user);
    },

    initials() {
      return initials(this.invitation.user);
    },

    positionAndCompany() {
      return positionAndCompany(this.invitation.user);
    },

    picture() {
      return this.invitation.user.picture;
    },

    isReceived() {
      return this.invitation.type === Constants.FRIENDS_INVITATION_REQUESTED;
    },
  },

  methods: {
    ...mapActions("friends", ["acceptInvitation", "rejectInvitation"]),

    openUserInfo() {
      this.$buefy.modal.open({
        parent: this,
        component: UserProfileModal,
        hasModalCard: true,
        canCancel: true,
        trapFocus: true,
        props: {
          userId: this.invitation.user.user_identity_token,
        },
      });
    },
  },
};
</script>

<style scoped>
.accept-button {
}

.button {
  width: 100%;
  margin-top: 8px;
}

.reject-button {
  margin-top: 10px;
  background: linear-gradient(0deg, rgba(89, 105, 123, 0.5), rgba(89, 105, 123, 0.5));
}

.cancel-button {
  margin-top: 22px;
  background: linear-gradient(0deg, #59697b, #59697b);
}

.info-button {
  cursor: pointer;
}

.title {
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
}

.subtitle {
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 8px;
  margin-top: 2px;
}
</style>
