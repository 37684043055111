<template>
  <div ref="friendListElement" class="columns">
    <template v-if="friends && friends.length">
      <div v-for="friend in friends" :key="friend.user_identity_token" class="column is-half">
        <attendee-list-item :user="friend" :is-current-user="false" :inbox-enabled="inboxEnabled"></attendee-list-item>
      </div>
      <list-loading-indicator :active="isLoading"></list-loading-indicator>
    </template>
    <div v-else-if="showEmptyPlaceholder" class="placeholder-container has-text-secondary">
      <div v-if="searchQuery.length">
        {{ $t("inbox.empty_friends_search_placeholder") }}
      </div>
      <div v-else>
        {{ $t("inbox.empty_friends_placeholder") }}
      </div>
    </div>
    <div v-else>
      <list-loading-indicator :active="isLoading"></list-loading-indicator>
    </div>
  </div>
</template>

<script>
import AttendeeListItem from "@/web/components/attendees/AttendeeListItem";
import ListLoadingIndicator from "@/shared/components/common/loading/ListLoadingIndicator";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { throttle } from "@/shared/utils";

export default {
  name: "FriendList",

  components: { AttendeeListItem, ListLoadingIndicator },

  props: {
    searchQuery: {
      type: String,
      default: "",
    },
  },

  mounted() {
    this.addScrollListener();
  },

  beforeDestroy() {
    this.removeScrollListener();
  },

  data() {
    return {
      scrollListener: null,
    };
  },

  computed: {
    ...mapGetters("inboxUsers", ["getFriends", "isLoading"]),
    ...mapGetters(["inboxEnabled"]),

    friends() {
      return this.getFriends(this.searchQuery);
    },

    scrollThreshold: () => 200,

    showEmptyPlaceholder() {
      return !this.isLoading && this.friends && !this.friends.length;
    },
  },

  methods: {
    ...mapActions("inboxUsers", ["loadFriendsPage", "searchFriends"]),
    ...mapMutations("inboxUsers", ["clearSearchResult"]),

    addScrollListener() {
      if (this.$refs.friendListElement && this.scrollListener === null) {
        this.scrollListener = ev => {
          const friendsList = ev.target;
          let isOnBottomOfList = friendsList.scrollTop > friendsList.scrollHeight - friendsList.clientHeight - this.scrollThreshold;
          if (isOnBottomOfList) {
            this.onBottomOfScroll();
          }
        };
        this.$refs.friendListElement.addEventListener("scroll", this.scrollListener);
      }
    },

    removeScrollListener() {
      if (this.scrollListener) {
        this.$refs.friendListElement.removeEventListener("scroll", this.scrollListener);
        this.scrollListener = null;
      }
    },

    onBottomOfScroll: throttle(function (newVal) {
      this.loadFriendsPage();
    }, 1000),
  },
};
</script>

<style scoped>
.columns {
  flex-wrap: wrap;
}

.column {
  padding-bottom: 0;
}

.placeholder-container {
  height: calc(100vh - 500px);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
</style>
